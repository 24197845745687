import React, { useEffect, useState } from "react";
import style from "./upload-picture.module.css";
import { API_URL } from "../components/API-URL";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import BackArrow from "../components/back-arrow";
import imageCompression from "browser-image-compression";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgress from "@mui/material/LinearProgress";

function Upload() {
    const [files, setFiles] = useState([]);
    const [reload, setReload] = useState(false);
    const [cat, setCat] = useState("");
    const [progress, setProgress] = useState(0);
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const navigate = useNavigate();

    const removeFile = (index) => {
        const tmp = files;
        tmp.splice(index, 1);
        setFiles(tmp);
        setReload(!reload);
    };

    const appendFiles = async (newFiles, event) => {
        event.preventDefault();
        const tmp = files;
        const readFileAsDataURL = (file) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();

                reader.onloadend = () => resolve(reader.result);
                reader.onerror = reject;

                reader.readAsDataURL(file);
            });

        setProgress(0);
        for (let i = 0; i < newFiles.length; i++) {
            try {
                const preview = await readFileAsDataURL(newFiles[i]);
                const file = newFiles[i];
                const options = {
                    maxSizeMB: 0.4,
                    maxWidthOrHeight: 800,
                };
                try {
                    const compressedFile = await imageCompression(
                        file,
                        options
                    );
                    tmp.push({ img: compressedFile, preview });
                    setProgress((100 * (i + 1)) / newFiles.length);
                } catch (error) {
                    console.error("Erreur lors de la compression :", error);
                }
            } catch (error) {
                console.error("Erreur lors de la lecture du fichier", error);
            }
        }

        setProgress(0);
        setFiles(tmp);
        document.getElementById("images").value = "";
        setReload(!reload);
    };

    useEffect(() => {
        if (!sessionStorage.getItem("token")) {
            navigate("/");
        }
        const dropContainer = document.getElementById("dropcontainer");

        dropContainer.addEventListener(
            "dragover",
            (e) => {
                e.preventDefault();
            },
            false
        );

        dropContainer.addEventListener("dragenter", () => {
            dropContainer.classList.add("drag-active");
        });

        dropContainer.addEventListener("dragleave", () => {
            dropContainer.classList.remove("drag-active");
        });

        dropContainer.addEventListener("drop", (e) => {
            dropContainer.classList.remove("drag-active");
            appendFiles(e.dataTransfer.files, e);
        });
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (buttonDisabled) return;
        setButtonDisabled(true);

        await Promise.all(
            files.map(async (file, i) => {
                try {
                    const response = await fetch(API_URL + "/api/bo/pictures", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${sessionStorage.getItem(
                                "token"
                            )}`,
                        },
                        body: JSON.stringify({
                            cat: cat,
                            contentType: file.img.type,
                        }),
                    });

                    if (response.status !== 200) {
                        console.error(
                            "Erreur lors de l'upload de l'image:",
                            file.name,
                            response
                        );
                        return;
                    }

                    const data = await response.json();

                    await fetch(data.url, {
                        method: "PUT",
                        body: file.img,
                        headers: {
                            "Content-Type": file.img.type,
                        },
                    });

                    return {};
                } catch (error) {
                    console.error(
                        "Erreur lors de l'upload de l'image:",
                        file.name,
                        error
                    );
                }
            })
        );
        navigate("/menu");
    };

    return (
        <div className={style.bodyDiv}>
            <BackArrow to={"/menu"} />
            <div className={style.UploadMainContainer}>
                <h1 className={style.title}>Ajouter des photos</h1>
                <div className={style.UploadContainer}>
                    <div className={style.form}>
                        <label
                            htmlFor="images"
                            className={style.drop_container}
                            id="dropcontainer"
                            style={{ width: "92%" }}
                        >
                            <span className={style.drop_title}>
                                Déposez vos images ici
                            </span>
                            ou
                            <input
                                type="file"
                                id="images"
                                accept="image/png, image/jpeg, image/gif"
                                multiple
                                required
                                onChange={(e) => appendFiles(e.target.files, e)}
                                className={style.inputFile}
                            />
                        </label>
                        <div style={{ width: "100%", marginTop: "10px", display: progress > 0 ? "block" : "none" }}>
                            <LinearProgress variant="determinate" value={progress} />
                        </div>
                        {files.length > 0 && (
                            <div style={{ width: "100%" }}>
                                <div className={style.imagesPreview}>
                                    {files.map((file, index) => {
                                        return (
                                            <div
                                                className={
                                                    style.singleImgContainer
                                                }
                                                key={index}
                                            >
                                                <div
                                                    className={style.removeImg}
                                                    onClick={() =>
                                                        removeFile(index)
                                                    }
                                                >
                                                    <DeleteIcon
                                                        style={{
                                                            color: "#BA6868",
                                                        }}
                                                    />
                                                </div>
                                                <img
                                                    key={index}
                                                    src={file.preview}
                                                    alt="Preview"
                                                    style={{
                                                        height: "100px",
                                                    }}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>

                                <div
                                    style={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <select
                                        className={style.catSelector}
                                        onChange={(e) => setCat(e.target.value)}
                                    >
                                        <option value="">
                                            --Choisir une catégorie--
                                        </option>
                                        <option value="nature">Nature</option>
                                        <option value="chic">Chic</option>
                                        <option value="champetre">
                                            Champêtre
                                        </option>
                                        <option value="boeme">Boème</option>
                                        <option value="color">
                                            Tout en couleur
                                        </option>
                                        <option value="theme">À Thème</option>
                                        <option value="accessoires">
                                            Accessoires
                                        </option>
                                        <option value="bouquet">
                                            Bouquets de Mariée
                                        </option>
                                        <option value="structures">
                                            Structures & panneaux
                                        </option>
                                        <option value="ceremonies">
                                            Cérémonies
                                        </option>
                                        <option value="decorations">
                                            Décorations de salle
                                        </option>
                                    </select>
                                    {cat && (
                                        <button
                                            className={style.submit}
                                            type="submit"
                                            disabled={buttonDisabled}
                                            onClick={handleSubmit}
                                        >
                                            Valider
                                            <div
                                                style={{
                                                    display: buttonDisabled
                                                        ? "inline"
                                                        : "none",
                                                    marginLeft: "10px",
                                                }}
                                            >
                                                <CircularProgress size="1.5em" />
                                            </div>
                                        </button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Upload;
