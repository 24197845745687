import style from "./Login.module.css";
import { useState } from "react";
import { API_URL } from "../components/API-URL";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    const login = async () => {
        setButtonDisabled(true);
        const req = await fetch(`${API_URL}/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                username: email,
                password,
            }),
        });
        setButtonDisabled(false);
        const data = await req.json();
        if (data.msg === "ok") {
            sessionStorage.setItem("token", data.data.token);
            navigate("/menu");
        } else if (data.msg === "bad credentials") {
            setError("Mauvais identifiants");
        } else if (data.msg === "missing username or password") {
            setError("Mauvais identifiants");
        } else {
            setError("Erreur Merci de contacter le developpeur");
        }
    };

    const handleKeyDown = (event) => {
        if (event.key === "Enter") {
            login();
        }
    };

    return (
        <div className={style.bodyDiv}>
            <div className={style.MainDiv}>
                <h1 className={style.title}>Connexion</h1>

                <div className={style.form}>
                    <input
                        className={style.input}
                        type="email"
                        placeholder="Email"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setEmail(e.currentTarget.value)}
                    />
                    <input
                        className={style.input}
                        type="password"
                        placeholder="Mot de passe"
                        onKeyDown={handleKeyDown}
                        onChange={(e) => setPassword(e.currentTarget.value)}
                    />
                    <button
                        disabled={buttonDisabled}
                        className={style.button}
                        onClick={login}
                    >
                        Connexion
                        <div
                            style={{
                                display: buttonDisabled ? "inline" : "none",
                                marginLeft: "10px",
                            }}
                        >
                            <CircularProgress size="1.5em" />
                        </div>
                    </button>
                    {error && <p className={style.error}>{error}</p>}
                </div>
            </div>
        </div>
    );
};

export default Login;
